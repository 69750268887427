*, *::before, *::after {
  box-sizing: border-box;
  font-family: "Roboto"
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

body {
  margin: 0;
  padding: 0;
  letter-spacing: 0.5px;
  width: 100%;
  height: 100%;
  animation: fadeIn 2s;
}

.container > form {
  height: 100%;
  width: 100%;
  padding: 50px 0;
}

.contactTop {
  padding-top: 50px;
  text-align: center;
  font-weight: bold;
}

.headerTop {
  padding-bottom: 30px;
  padding-top: 50px;
  text-align: center;
  font-weight: bold;
}

:global(.container) {
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
}

.navbar {
  height: 100px;
  text-align: center;
}

.nav-link {
  margin-right: 30px;
  text-align: center;
}

.form-wrapper {
  width: 800px;
  max-width: 100%;
}

.form-section {
  padding-bottom: 50px;
}

.form-wrapper .form-control {
  border: 0;
  border-bottom: 1px solid #e7e7e7;
  border-radius: 0;
  font-size: 14px;
  font-weight: bold;
  padding: 15px 10px;
  margin: 50px 0;
}

.card {
  margin-bottom: 50px;
}

.card .info {
  margin-left: 10px;
  font-size: 15px;
}

.card-body {
  margin-top: 10px;
  margin-left: 5px;
}

.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 790px;
  overflow: hidden;
}

.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}

.slide.active {
  opacity: 1;
}

.carousel img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  filter: brightness(0.5);
}

.text {
  color: white;
  font-size: 35px;
  text-align: center;
  z-index: 10;
  pointer-events: none;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  width: 70%;
}

.carousel button {
  position: absolute;
  z-index: 11;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  border: none;
  cursor: pointer;
  font-size: 24px;
  padding: 0 20px;
}

.carousel button.prev {
  left: 10px;
}

.carousel button.next {
  right: 10px;
}

.apText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  font-size: 36px;
}

.card-header:first-child {
  border-radius: .25rem .25rem 0 0;
}
.p-3 {
  padding: 1rem!important;
}
.align-items-start {
  align-items: flex-start!important;
}
.flex-column {
  flex-direction: column!important;
}
.border-bottom {
  border-bottom: 1px solid #cbd5e0!important;
}
.card-footer, .card-header {
  display: flex;
  align-items: center;
}
.card-header {
  padding: .5rem 1rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 0 solid rgba(0,0,0,.125);
}
.card-footer:last-child {
  border-radius: 0 0 .25rem .25rem;
}
.h3, h3 {
  font-size: 1.75rem;
}
.p-3 {
  padding: 1rem!important;
}
.justify-content-center {
  justify-content: center!important;
}
.card-footer, .card-header {
  display: flex;
  align-items: center;
}
.card-footer {
  padding: .5rem 1rem;
  background-color: #fff;
  border-top: 0 solid rgba(0,0,0,.125);
}
.card-ap {
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06);
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0,0,0,.125);
  border-radius: .25rem;
}

.atrakcje-img {
  display: flex;
  align-items: center; 
  margin-bottom: 20px;
}

.ap-img {
  display: flex; 
  justify-content: space-between; 
  margin: 20px 0; 
  flex-wrap: wrap;
}

.ap-src {
  width: 420px;
  height: 350px; 
  transition: transform 0.3s ease;
}

@media screen and (max-width: 800px) {
  .nav-link {
    margin-right: 0;
    text-align: center;
  }

  .apText {
    font-size: 15px;
  }

  .navbar {
    font-size: 8px;
    font-weight: bold;
  }

  .atrakcje-img {
    display: block;
  }

  .ap-img {
    display: block;
  }

  .ap-src {
    width: 370px;
    height: 300px;
  }

  .card .info {
    font-size: 9px;
    font-weight: bold;
  }

  .text {
    font-size: 30px;
  }
}

@import "./typography.scss";